import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { Button } from 'antd';
import ToastNotification from '../../../global/ToastNotification';
import StripeErrorMessage from '../../../global/FormElements/StripeErrorMessage';
import { CARD_OPTIONS } from '../../../../constants';

const AddCreditCardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [formError, setFormError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { handleCloseModal, setupIntent, setSetupIntent, getWallet } = props;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (formError) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    // Card setup confirmation
    const { setupIntent: updatedSetupIntent, error } =
      await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: { card: cardElement },
      });

    if (error) {
      console.log(error);
      setProcessing(false);
      ToastNotification({
        title: 'Error',
        message:
          'Something went wrong while processing new Credit Card. Please try again.',
      });
      cardElement.clear();
      handleCloseModal();
    } else {
      setSetupIntent(updatedSetupIntent);
      setProcessing(false);
      await getWallet();
      ToastNotification({
        title: 'Success',
        message: 'Credit card added to your wallet!!',
      });
      cardElement.clear();
      handleCloseModal();
    }
  };

  const handleCancel = (event) => {
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);

    cardElement.clear();
    handleCloseModal();
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="form-group">
        <CardElement
          options={CARD_OPTIONS}
          onChange={(e) => {
            setFormError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {formError && (
        <StripeErrorMessage>{formError.message}</StripeErrorMessage>
      )}
      <button
        className={`submit-btn ${formError ? 'error' : ''}`}
        type="submit"
        disabled={processing || !stripe}
      >
        {processing ? 'Processing...' : 'Add Credit Card'}
      </button>
      <Button
        className={`cancel-btn ${formError ? 'error' : ''}`}
        type="danger"
        onClick={handleCancel}
      >
        Cancel
      </Button>
    </form>
  );
};

export default AddCreditCardForm;
