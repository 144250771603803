import { useState } from 'react';
import { Menu, Drawer, Form, Input, Button, Modal } from 'antd';

const { confirm } = Modal;

const ModalMenu = (props) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [form] = Form.useForm();
  const { onConfirmDeletion, playlistInformation, onSubmitUpdate } = props;

  const handleOpenDrawer = () => {
    setVisibleDrawer(true);
  };

  const handleCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const confirmDeletePlaylist = () => {
    confirm({
      title: 'Confirm Delete Playlist',
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleOnConfirmDeletion,
      centered: true,
      className: 'library-action-menu-modal',
      width: '275px',
    });
  };

  const onClickMenu = (event) => {
    if (event.key === 'edit') {
      handleOpenDrawer();
    } else if (event.key === 'delete') {
      confirmDeletePlaylist();
    }
  };

  const handleOnConfirmDeletion = () => {
    onConfirmDeletion(playlistInformation);
  };

  const onFinish = async (values) => {
    const updatedPlaylistInformation = { ...playlistInformation, ...values };

    onSubmitUpdate(updatedPlaylistInformation);
    handleCloseDrawer();
  };

  return (
    <>
      <Menu theme="dark" selectable={false} onClick={onClickMenu}>
        <Menu.Item key="edit">Edit playlist information</Menu.Item>
        <Menu.Item key="delete">Delete this playlist</Menu.Item>
      </Menu>
      <Drawer
        className="playlist-drawer"
        placement="right"
        title="Edit Playlist Information"
        onClose={handleCloseDrawer}
        visible={visibleDrawer}
      >
        <Form
          name="update-playlist"
          form={form}
          initialValues={{
            title: playlistInformation.title || '',
            description: playlistInformation.description || '',
          }}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Playlist must have a title!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" shape="round">
              Save
            </Button>
            <Button
              danger
              type="primary"
              shape="round"
              style={{ marginLeft: '8px' }}
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ModalMenu;
