import { CheckCircleTwoTone } from '@ant-design/icons';
import Loader from '../../global/Loader';

const ResultMessage = (props) => {
  const { paymentMethod, paymentProduct, selectedSubscription } = props;

  const selectedSubscriptionInfo = paymentProduct.find(
    (product) => product.id === selectedSubscription
  );
  const subscription = `${selectedSubscriptionInfo.recurring.interval_count} ${selectedSubscriptionInfo.recurring.interval} subscription`;

  return (
    <div className="result">
      <div className="result-title" role="alert">
        <CheckCircleTwoTone
          className="payment-success-icon"
          twoToneColor="#52c41a"
        />
        Payment Successful!
      </div>
      <div className="result-message">
        Thank you! Your payment for <b className="capitalize">{subscription}</b>{' '}
        has been received.
        <br></br>Generated payment id: {paymentMethod.id}
        <br></br>We are now going to redirect you to the music player... Hope
        you enjoy the music!
      </div>
      <Loader />
    </div>
  );
};

export default ResultMessage;
