import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiService as axios } from '../../services/network';

const initialState = {
  tags: {
    data: [],
    status: 'idle',
    error: null,
  },
  songs: {
    data: [],
    moreSongs: true,
    status: 'idle',
    error: null,
  },
};

// thunk functions to connect to api
export const fetchTags = createAsyncThunk('tags/fetchTags', async () => {
  const { data } = await axios.get('/tags');

  return data;
});

export const fetchSongsByTag = createAsyncThunk(
  'songs/fetchSongsByTag',
  async ({ tag, page = 0 }) => {
    const { data } = await axios.get(`/songs?tag=${tag}&page=${page}`);

    return data;
  }
);

const musicLibrarySlice = createSlice({
  name: 'musicLibrary',
  initialState,
  reducers: {
    getAllTags(state, action) {},
    clearSongs(state, action) {
      state.songs.data = [];
      state.songs.moreSongs = true;
      state.songs.status = 'idle';
      state.songs.error = null;
    },
  },
  extraReducers: {
    [fetchTags.pending]: (state, action) => {
      state.tags.status = 'loading';
    },
    [fetchTags.fulfilled]: (state, action) => {
      state.tags.status = 'succeeded';
      state.tags.data = state.tags.data.concat(action.payload);
    },
    [fetchTags.rejected]: (state, action) => {
      state.tags.status = 'failed';
      state.tags.error = action.error.message;
    },
    [fetchSongsByTag.pending]: (state, action) => {
      if (state.songs.status === 'idle') {
        state.songs.status = 'loading';
      }
    },
    [fetchSongsByTag.fulfilled]: (state, action) => {
      const songsWithAudio = action.payload.filter((song) => song.audio);

      if (!songsWithAudio.length && state.songs.status === 'succeeded') {
        state.songs.moreSongs = false;

        return;
      }

      state.songs.status = 'succeeded';
      state.songs.data = state.songs.data.concat(songsWithAudio);
    },
    [fetchSongsByTag.rejected]: (state, action) => {
      state.songs.status = 'failed';
      state.tags.error = action.error.message;
    },
  },
});

export const { getAllTags, clearSongs } = musicLibrarySlice.actions;

export default musicLibrarySlice.reducer;

export const selectAllTags = (state) => state.musicLibrary.tags.data;

export const selectAllSongs = (state) => state.musicLibrary.songs.data;

export const selectMoreSongs = (state) => state.musicLibrary.songs.moreSongs;
