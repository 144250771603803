import { Row, Col, Button, Divider } from 'antd';
import { EllipsisOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { intervalToDuration } from 'date-fns';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  playSong,
  setPlayerActive,
  selectActivePlayer,
} from '../../../features/player/playerSlice';

const SongInformation = (props) => {
  const { songInformation, onShowModal, userInformation } = props;
  const { title } = songInformation;
  const artistAlbumInformation = `${songInformation.project_artist} - ${songInformation.project_title}`;
  const dispatch = useDispatch();
  const isPlayerActive = useSelector(selectActivePlayer);
  const songDuration = songInformation['duration-ms'] || 0;
  const durationObj = intervalToDuration({ start: 0, end: songDuration });
  const durationString = `${durationObj.minutes}:${durationObj.seconds}`;

  const handlePlaySong = () => {
    dispatch(playSong({ songInformation, userInformation }));

    if (!isPlayerActive) {
      dispatch(setPlayerActive(true));
    }
  };

  return (
    <>
      <Divider className="song-information-divider" />
      <Row
        className="song-information-component"
        align="middle"
        justify="center"
      >
        <Col span={3}>
          <Button
            ghost
            className="play-button"
            shape="circle"
            size="small"
            onClick={handlePlaySong}
          >
            <PlayCircleOutlined />
          </Button>
        </Col>
        <Col span={17}>
          <p title={title.length > 17 ? title : ''} className="song-title">
            {title}
            <small> - {durationString}</small>
          </p>
          <span
            title={
              artistAlbumInformation.length > 37 ? artistAlbumInformation : ''
            }
            className="artist-album-information ib"
          >
            {artistAlbumInformation}
          </span>
        </Col>
        <Col span={3}>
          <Button
            ghost
            className="action-btn"
            onClick={() => onShowModal(songInformation)}
          >
            <EllipsisOutlined rotate={90} />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SongInformation;
