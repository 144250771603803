import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiService as axios } from '../../services/network';

const initialState = {
  playlists: {
    data: [],
    status: 'idle',
    error: null,
  },
  playlistDetail: {
    data: {},
    status: 'idle',
    error: null,
  },
};

// thunk functions to connect to api
export const fetchAllPlaylists = createAsyncThunk(
  'playlists/fetchAllPlaylists',
  async () => {
    const { data } = await axios.get('/playlists');

    return data;
  }
);

export const fetchPlaylistbyId = createAsyncThunk(
  'playlists/fetchPlaylistbyId',
  async ({ playlistId }) => {
    const { data } = await axios.get(`/playlists/${playlistId}`);

    return data;
  }
);

export const createPlaylist = createAsyncThunk(
  'playlists/createPlaylist',
  async ({ playlistInfo }) => {
    const { data } = await axios.post('/playlists', playlistInfo);

    return data;
  }
);

export const deletePlaylist = createAsyncThunk(
  'playlists/deletePlaylist',
  async ({ playlistInfo }) => {
    const { uipc: id } = playlistInfo;
    const { data } = await axios.delete(`/playlists/${id}`);

    return data;
  }
);

export const updatePlaylist = createAsyncThunk(
  'playlists/updatePlaylist',
  async ({ playlistInfo }) => {
    const { uipc: id } = playlistInfo;
    const { data } = await axios.patch(`/playlists/${id}`, playlistInfo);

    return data;
  }
);

const playlistSlice = createSlice({
  name: 'playlistsLibrary',
  initialState,
  reducers: {
    clearPlaylistDetail(state, action) {
      state.playlistDetail.data = {};
      state.playlistDetail.status = 'idle';
      state.playlistDetail.error = null;
    },
  },
  extraReducers: {
    [fetchAllPlaylists.pending]: (state, action) => {
      state.playlists.status = 'pending';
    },
    [fetchAllPlaylists.fulfilled]: (state, action) => {
      state.playlists.status = 'succeeded';
      state.playlists.data = state.playlists.data.concat(action.payload);
    },
    [fetchAllPlaylists.rejected]: (state, action) => {
      state.playlists.status = 'failed';
      state.playlists.error = action.error.message;
    },
    [createPlaylist.fulfilled]: (state, action) => {
      state.playlists.data.push(action.payload);
    },
    [deletePlaylist.fulfilled]: (state, action) => {
      const indexDeletedPlaylist = state.playlists.data.findIndex(
        (playlist) => playlist.uipc === action.payload
      );

      state.playlists.data.splice(indexDeletedPlaylist, 1);
    },
    [updatePlaylist.fulfilled]: (state, action) => {
      const updatedPlaylist = action.payload;
      const indexUpdatedPlaylist = state.playlists.data.findIndex(
        (playlist) => playlist.uipc === updatedPlaylist.uipc
      );

      state.playlists.data.splice(indexUpdatedPlaylist, 1, updatedPlaylist);

      if (state.playlistDetail.status === 'succeeded') {
        const updatedSongs = state.playlistDetail.data.songs.filter((song) =>
          updatedPlaylist.songs.includes(song.uipc)
        );

        state.playlistDetail.data = { ...updatedPlaylist, songs: updatedSongs };
      }
    },
    [fetchPlaylistbyId.pending]: (state, action) => {
      state.playlistDetail.status = 'pending';
    },
    [fetchPlaylistbyId.fulfilled]: (state, action) => {
      state.playlistDetail.status = 'succeeded';
      state.playlistDetail.data = action.payload;
    },
    [fetchPlaylistbyId.rejected]: (state, action) => {
      state.playlistDetail.status = 'failed';
      state.playlistDetail.error = action.error.message;
    },
  },
});

export const { addToPlaylist, clearPlaylistDetail } = playlistSlice.actions;

export default playlistSlice.reducer;

export const selectAllPlaylists = (state) => state.playlist.playlists.data;

export const selectPlaylistSongs = (state) =>
  state.playlist.playlistDetail.data;
