import Icon from '@ant-design/icons';
import { CreditCardTwoTone } from '@ant-design/icons';
import * as PayIcons from 'react-pay-icons';
import { capitalize } from '../../../utilities';

const iconStyles = { fontSize: '2.75rem', verticalAlign: 'middle' };

const PaymentIcon = ({ type = '' }) => {
  const IconComponent = PayIcons[capitalize(type)];

  if (!type.length || !IconComponent) {
    return <CreditCardTwoTone style={iconStyles} />;
  }

  return <Icon style={iconStyles} component={IconComponent} />;
};

export default PaymentIcon;
