import { Route, Switch } from 'react-router-dom';
import './styles/App.scss';

import Login from './containers/Login';
import MainPanel from './containers/MainPanel';
import Payment from './containers/Payment';
import RedirectAs404 from './components/global/RedirectAs404';

const App = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/payment" component={Payment} />
      <Route path="/" component={MainPanel} />
      <Route route="*" component={RedirectAs404} />
    </Switch>
  );
};

export default App;
