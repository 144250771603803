import '../../../styles/components/SideBar.scss';

import { Button } from 'antd';
import { NavLink } from 'react-router-dom';

// Ant Design Icons
import {
  UnorderedListOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { ReactComponent as headphoneSvg } from '../../../assets/icons/headphones.svg';
import { ReactComponent as librarySvg } from '../../../assets/icons/music-library.svg';

const SideBarMenu = () => {
  return (
    <ul className="player-sidebar">
      <li>
        <NavLink exact to="/">
          <Button className="sidebar-button" ghost>
            <Icon component={headphoneSvg} />
          </Button>
        </NavLink>
      </li>
      <li>
        <NavLink exact to="/search">
          <Button className="sidebar-button" ghost>
            <SearchOutlined />
          </Button>
        </NavLink>
      </li>
      <li>
        <NavLink to="/browse">
          <Button className="sidebar-button" ghost>
            <Icon component={librarySvg} />
          </Button>
        </NavLink>
      </li>
      <li>
        <NavLink to="/playlists">
          <Button className="sidebar-button" ghost>
            <UnorderedListOutlined />
          </Button>
        </NavLink>
      </li>
      <li>
        <NavLink exact to="/settings">
          <Button className="sidebar-button" ghost>
            <SettingOutlined />
          </Button>
        </NavLink>
      </li>
    </ul>
  );
};

export default SideBarMenu;
