import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button } from 'antd';
import ToastNotification from '../../global/ToastNotification';

const CreatePlaylistForm = (props) => {
  const { isDrawerVisible, createPlaylist, handleCloseDrawer, userObject } =
    props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isDrawerVisible) {
      form.resetFields();
    }
  }, [isDrawerVisible, form]);

  const onFinish = async (values) => {
    const playlistInfo = {
      ...values,
      clientId: userObject.clientId,
      followers: 0,
      tags: [],
      songs: [],
      public: false,
    };

    try {
      await dispatch(createPlaylist({ playlistInfo }));
      form.resetFields();
      handleCloseDrawer();
      ToastNotification({
        title: 'Success',
        message: 'Playlist was successfully created!',
      });
    } catch (error) {
      form.resetFields();
      handleCloseDrawer();
      ToastNotification({
        title: 'Error',
        message: 'Something went wrong. Please try again.',
      });
    }
  };

  return (
    <Form
      name="create-playlist"
      form={form}
      initialValues={{
        title: '',
        description: '',
      }}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: 'Playlist must have a title!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" shape="round">
          Save
        </Button>
        <Button
          danger
          type="primary"
          shape="round"
          style={{ marginLeft: '8px' }}
          onClick={handleCloseDrawer}
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePlaylistForm;
