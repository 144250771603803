import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Row, Col, Image } from 'antd';
import PaymentForm from '../../components/Payment';
import { stripeFEKey } from '../../constants';
import '../../styles/Payment.scss';

// logos
import stripe from '../../assets/logos/powered-stripe.svg';

const stripePromise = loadStripe(stripeFEKey);

const Payment = () => {
  return (
    <div className="payment-container panel-box-shadow">
      <h1 className="payment-ttl">Noizie Subscription Plan</h1>
      <p className="payment-paragraph">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi nostrum
        cupiditate maxime voluptates iusto tempora asperiores, ad ipsum alias
        quasi. Culpa nemo nostrum eius dignissimos sapiente voluptatem molestiae
        accusantium numquam?
      </p>
      {/* <p className="payment-paragraph">For $5 a month get access to a vast catalog of stream safe songs for you to use.</p> */}
      <Elements stripe={stripePromise}>
        <PaymentForm />
      </Elements>
      <Row align="middle" justify="end">
        <Col pull={1}>
          <Image
            src={stripe}
            className="stripe-logo"
            alt="Powered by Stripe"
            preview={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Payment;
