import { useState, useEffect } from 'react';
import { Row, Col, Button, Avatar, Collapse, Breadcrumb, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useAuthentication } from '../../context/authContext';
import { apiService as axios } from '../../services/network';
import ToastNotification from '../global/ToastNotification';
import AddCreditCardModal from './AddCreditCardModal';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import CrediCardDropdown from './CreditCardDropdown';
import '../../styles/components/SettingsPanel.scss';

const { Panel } = Collapse;
const { confirm } = Modal;

const SettingsPanel = () => {
  const { userInfo, logout, updateUser } = useAuthentication();
  const { picture, name, cancel_at_period_end, subscriptionStatus } = userInfo;
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalProcessing, setIsModalProcessing] = useState(false);
  const [walletInformation, setWalletInformation] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('');
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [setupIntent, setSetupIntent] = useState(null);

  const getCreditCardWallet = async () => {
    try {
      const { data } = await axios.get('/payment/wallet');
      const { paymentMethods, customer } = data;

      setWalletInformation(paymentMethods.data);
      setDefaultPaymentMethod(customer.invoice_settings.default_payment_method);
    } catch (error) {
      console.error(error);
      ToastNotification({
        title: 'Error',
        message: 'Something went wrong. Unabled to get your payment methods.',
      });
    }
  };

  const handleLogout = () => {
    logout();
    // Redirecting to login panel
    history.push('/login');
  };

  const handleModalConfirm = async () => {
    try {
      setIsModalProcessing(true);
      const { data } = await axios.post('/payment/subscriptions/cancel', {
        subscriptionId: userInfo.subscriptionId,
      });
      const { cancel_at_period_end } = data;
      const updatedUserInfo = {
        ...userInfo,
        cancel_at_period_end,
      };

      // Updating user information
      updateUser({ userInfo: updatedUserInfo });
      setIsModalProcessing(false);
      setIsModalVisible(false);
      ToastNotification({
        title: 'Success',
        message:
          'Current Subscription will be cancelled at the end of its period.',
      });
    } catch (error) {
      console.error(error);
      ToastNotification({
        title: 'Error',
        message:
          'Something went wrong. Your subscription was not cancelled, please try again.',
      });
    }
  };

  useEffect(() => {
    getCreditCardWallet();

    return () => setWalletInformation([]);
  }, [setWalletInformation]);

  const handleSetDefaultPaymentMethod = async (paymentMethodId) => {
    try {
      const { status } = await axios.post('/payment/wallet/default', {
        payment_method: paymentMethodId,
      });

      if (status === 201) {
        await getCreditCardWallet();
        setDefaultPaymentMethod(paymentMethodId);
        ToastNotification({
          title: 'Success',
          message: 'Default payment method was successfully updated.',
        });
      }
    } catch (error) {
      console.error(error);
      ToastNotification({
        title: 'Error',
        message:
          'Something went wrong. Default payment method was not updated, please try again.',
      });
    }
  };

  const handleRemovePaymentMethod = async (paymentMethodId) => {
    try {
      const { status } = await axios.post('/payment/wallet/remove', {
        payment_method: paymentMethodId,
      });

      if (status === 201) {
        setDefaultPaymentMethod(paymentMethodId);
        await getCreditCardWallet();
        ToastNotification({
          title: 'Success',
          message: 'Payment method was removed.',
        });
      }
    } catch (error) {
      console.error(error);
      ToastNotification({
        title: 'Error',
        message:
          'Something went wrong. Your payment method was not removed, please try again.',
      });
    }
  };

  const handleMenuClick = (paymentMethodId = '', type = '') => {
    if (type.length === 0 || paymentMethodId.length === 0) {
      return;
    }

    if (type === 'setDefault') {
      handleSetDefaultPaymentMethod(paymentMethodId);
    }

    if (type === 'remove') {
      handleRemovePaymentMethod(paymentMethodId);
    }
  };

  const handleAddCreditCard = async () => {
    try {
      const { data } = await axios.post('/payment/wallet', null);
      setSetupIntent(data);
      setIsFormModalVisible(true);
    } catch (error) {
      console.error(error);
      ToastNotification({
        title: 'Error',
        message:
          'Something went wrong while processing a new Credit Card. Please try again.',
      });
      setIsFormModalVisible(false);
    }
  };

  const confirmLogout = () => {
    confirm({
      title: 'Confirm Logout',
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleLogout,
      centered: true,
      className: 'library-action-menu-modal',
      width: '275px',
    });
  };

  return (
    <div className="settings-panel app-panel">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="mt-1rem" align="middle" justify="space-between">
        <Col span={8}>
          <Avatar
            icon={<UserOutlined />}
            size={80}
            src={picture}
            draggable="false"
          />
        </Col>
        <Col span={14}>
          <h4 className="txt-left capitalize">{name}</h4>
          <Button
            danger
            type="primary"
            className="logout-btn"
            onClick={confirmLogout}
          >
            Log out
          </Button>
        </Col>
        <Col span={20}>
          <p className="user-subscription-status">
            Subscription Status:
            <span
              className={`uppercase font-bold ${subscriptionStatus}-subscription`}
            >
              {` ${subscriptionStatus}`}
            </span>
          </p>
        </Col>
        {subscriptionStatus === 'active' && !cancel_at_period_end && (
          <Col span={15}>
            <Button
              danger
              type="primary"
              className="cancel-subscription-btn"
              onClick={() => setIsModalVisible(true)}
            >
              Cancel Subscription
            </Button>
          </Col>
        )}
        <Col span={24}>
          <Collapse
            accordion
            className="credit-cards-collapse"
            expandIconPosition="right"
          >
            <Panel header={<b>Set default credit card</b>} key="1">
              <CrediCardDropdown
                type="setDefault"
                walletInformation={walletInformation}
                handleMenuClick={handleMenuClick}
                defaultPaymentMethod={defaultPaymentMethod}
              />
            </Panel>
            <Panel header={<b>Add credit card</b>} key="2">
              <Button
                className="add-credit-card-btn"
                type="primary"
                onClick={handleAddCreditCard}
              >
                Add a credit card.
              </Button>
            </Panel>
            <Panel header={<b>Remove credit card</b>} key="3">
              <p className="collapsable-paragraph">
                Select the credit card you want to remove.
              </p>
              <ul className="collapsable-unordered-list">
                <li>
                  <small>Default credit card cannot be removed.</small>
                </li>
                <li>
                  <small>You must have at least one credit card.</small>
                </li>
              </ul>
              <CrediCardDropdown
                type="remove"
                walletInformation={walletInformation}
                handleMenuClick={handleMenuClick}
                defaultPaymentMethod={defaultPaymentMethod}
              />
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <AddCreditCardModal
        isModalVisible={isFormModalVisible}
        handleModalConfirm={() => setIsFormModalVisible(false)}
        handleCloseModal={() => setIsFormModalVisible(false)}
        setupIntent={setupIntent}
        setSetupIntent={setSetupIntent}
        getWallet={getCreditCardWallet}
      />
      <CancelSubscriptionModal
        isModalProcessing={isModalProcessing}
        isModalVisible={isModalVisible}
        handleModalConfirm={handleModalConfirm}
        onCancel={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default SettingsPanel;
