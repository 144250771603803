import { useRef, useState } from 'react';
import { Button, Row, Col } from 'antd';
import Icon from '@ant-design/icons';

import '../../../styles/components/VolumeControls.scss';
import { ReactComponent as volumeSvg } from '../../../assets/icons/volume.svg';
import { ReactComponent as muteSvg } from '../../../assets/icons/mute.svg';

const VolumeMediaControls = (props) => {
  const { currentVolume, onVolumeUpdate, onTriggerMute, onTriggerUnMute } =
    props;
  const [isMute, setIsMute] = useState(false);
  const volumeBarRef = useRef(null);
  const maxVolume = 1;
  const currentVolumePercentage = (currentVolume / maxVolume) * 100;

  const calculateClickedVolume = (e) => {
    const clickPositionInPage = e.pageX;
    const barStart =
      volumeBarRef.current.getBoundingClientRect().left + window.scrollX;
    const barWidth = volumeBarRef.current.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = maxVolume / barWidth;
    const calculatedVolume = timePerPixel * clickPositionInBar;

    if (calculatedVolume > 1) {
      return 1;
    }
    if (calculatedVolume < 0) {
      return 0;
    }
    return calculatedVolume;
  };

  const handleVolumeDrag = (e) => {
    // nothing should happen if its not a left click
    if (e.button !== 0) {
      return;
    }
    onVolumeUpdate(calculateClickedVolume(e));

    const updateVolumeOnMove = (eMove) => {
      onVolumeUpdate(calculateClickedVolume(eMove));
    };

    document.addEventListener('mousemove', updateVolumeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateVolumeOnMove);
    });
  };

  const handleMute = () => {
    if (isMute) {
      setIsMute(false);
      onTriggerUnMute(true);
    } else {
      setIsMute(true);
      onTriggerMute(true);
    }
  };

  return (
    <div className="volume-controls-container">
      <Row align="middle" justify="center">
        <Col offset={12}>
          <Button ghost className="volume-btn" onClick={() => handleMute()}>
            {!isMute && <Icon component={volumeSvg} />}
            {isMute && <Icon component={muteSvg} />}
          </Button>
        </Col>
        <Col span={3}>
          <div className="volume-bar-wrapper">
            <div
              className={`volume-bar ${isMute ? 'disabled' : ''}`}
              ref={volumeBarRef}
              style={{
                background: `linear-gradient(to right, #3270ad ${currentVolumePercentage}%, #6f6f6f 0)`,
              }}
              onMouseDown={(e) => handleVolumeDrag(e)}
            >
              <span
                className="volume-bar-knob"
                style={{ left: `${currentVolumePercentage - 5}%` }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VolumeMediaControls;
