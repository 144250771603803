import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiService as axios } from '../../services/network';

const initialState = {
  searchSongsByTag: {
    data: [],
    status: 'idle',
    error: null,
  },
  searchSongsByName: {
    data: [],
    status: 'idle',
    error: null,
  },
  searchSongsByArtist: {
    data: [],
    status: 'idle',
    error: null,
  },
};

// thunk functions to connect to API
export const searchSongsByParam = createAsyncThunk(
  'search/searchSongsByParam',
  async ({ param }) => {
    const { data } = await axios.get(`/songs/quick-search?search=${param}`);

    return data;
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearchByTagResults(state, action) {
      state.searchSongsByTag.data = [];
      state.searchSongsByTag.status = 'idle';
      state.searchSongsByTag.error = null;
    },
  },
  extraReducers: {
    [searchSongsByParam.pending]: (state, action) => {
      state.searchSongsByTag.status = 'loading';
    },
    [searchSongsByParam.fulfilled]: (state, action) => {
      const songsWithAudio = action.payload.filter((song) => song.audio);

      state.searchSongsByTag.status = 'succeeded';
      state.searchSongsByTag.data = [...songsWithAudio];
    },
    [searchSongsByParam.rejected]: (state, action) => {
      state.searchSongsByTag.status = 'failed';
      state.searchSongsByTag.error = action.error.message;
    },
  },
});

export const { clearSearchByTagResults } = searchSlice.actions;

export default searchSlice.reducer;

export const selectSearchSongsByTagResults = (state) =>
  state.search.searchSongsByTag.data;
