import { useState, useEffect } from 'react';
import { Menu, Drawer, Row, Col, Button, Modal } from 'antd';
import Loader from '../Loader';

// redux implementation
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllPlaylists,
  updatePlaylist,
  selectAllPlaylists,
  clearPlaylistDetail,
} from '../../../features/playlists/playlistSlice';

const { confirm } = Modal;

const ActionsModalMenu = (props) => {
  const { selectedSong, onAddQueueHandler, handleCloseModal } = props;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const playlists = useSelector(selectAllPlaylists);
  const playlistsStatus = useSelector(
    (state) => state.playlist.playlists.status
  );
  const playlistsError = useSelector((state) => state.playlist.playlists.error);
  const dispatch = useDispatch();
  let drawerContent;

  useEffect(() => {
    if (playlistsStatus === 'idle' && visibleDrawer) {
      dispatch(fetchAllPlaylists());
    }
  }, [playlistsStatus, dispatch, visibleDrawer]);

  const onOpenDrawer = () => {
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const confirmAddtoQueue = () => {
    confirm({
      title: 'Add this song to Queue',
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onAddQueueHandler(selectedSong),
      centered: true,
      className: 'library-action-menu-modal',
      width: '275px',
    });
  };

  const onClickMenu = (event) => {
    if (event.key === 'playlist') {
      onOpenDrawer();
    } else if (event.key === 'queue') {
      confirmAddtoQueue();
    }
  };

  const onConfirmAddtoPlaylist = (playlistInformation) => {
    const { uipc: songId, tags: songTags } = selectedSong;
    const { songs: playlistSongs, tags: playlistTags } = playlistInformation;

    const updatedPlaylistSongs = [...playlistSongs, songId];
    const updatedPlaylistTags = [...new Set([...playlistTags, ...songTags])];

    const updatedPlaylistInfo = {
      ...playlistInformation,
      songs: updatedPlaylistSongs,
      tags: updatedPlaylistTags,
    };

    dispatch(updatePlaylist({ playlistInfo: updatedPlaylistInfo }));
    dispatch(clearPlaylistDetail());
    onCloseDrawer();
    handleCloseModal();
  };

  if (playlistsStatus === 'loading') {
    drawerContent = <Loader />;
  } else if (playlistsStatus === 'succeeded') {
    drawerContent = (
      <Row gutter={[16, 10]}>
        {playlists.map((playlist) => (
          <Col key={playlist.uipc} span={20}>
            <Button
              className="playlist-button"
              onClick={() => onConfirmAddtoPlaylist(playlist)}
              type="text"
            >
              {playlist.title}
            </Button>
          </Col>
        ))}
      </Row>
    );
  } else if (playlistsStatus === 'failed') {
    drawerContent = <p className="txt-center">{playlistsError}</p>;
  }

  return (
    <>
      <Menu theme="dark" selectable={false} onClick={onClickMenu}>
        <Menu.Item key="playlist">Add to playlist</Menu.Item>
        <Menu.Item key="queue">Add to queue</Menu.Item>
      </Menu>
      <Drawer
        className="playlist-drawer"
        placement="right"
        title="Add Song to Playlist"
        onClose={onCloseDrawer}
        visible={visibleDrawer}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default ActionsModalMenu;
