import { combineReducers } from 'redux';
import playerReducer from '../features/player/playerSlice';
import musicLibraryReducer from '../features/musicLibrary/musicLibrarySlice';
import playlistReducer from '../features/playlists/playlistSlice';
import searchReducer from '../features/search/searchSlice';

export default combineReducers({
  player: playerReducer,
  musicLibrary: musicLibraryReducer,
  playlist: playlistReducer,
  search: searchReducer,
});
