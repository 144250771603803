import { LoadingOutlined } from '@ant-design/icons';

const Loader = (props) => {
  const { isFullscreen = false } = props;
  return (
    <div
      className={`loader-wrapper txt-center ${
        isFullscreen ? 'fullscreen' : ''
      }`}
    >
      <LoadingOutlined />
    </div>
  );
};

export default Loader;
