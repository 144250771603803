import { Button, Row, Col } from 'antd';
import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  StepForwardOutlined,
  StepBackwardOutlined,
  RedoOutlined,
  LikeOutlined,
} from '@ant-design/icons';

import '../../../styles/components/MediaControls.scss';

const PlayerMediaControls = (props) => {
  const {
    isPlaying,
    onPlayPause,
    onNext,
    onPrevious,
    onRepeat,
    isRepeat,
    playDisabled,
  } = props;

  return (
    <div className="media-controls-container">
      <Row align="middle" justify="center">
        <Col>
          <Button ghost>
            <LikeOutlined />
          </Button>
        </Col>
        <Col>
          <Button
            ghost
            className="step-back-foward-btn"
            onClick={() => onPrevious()}
          >
            <StepBackwardOutlined />
          </Button>
        </Col>
        <Col>
          <Button
            ghost
            className="play-pause-btn"
            onClick={onPlayPause}
            disabled={playDisabled}
          >
            {isPlaying && <PauseCircleOutlined />}
            {!isPlaying && <PlayCircleOutlined />}
          </Button>
        </Col>
        <Col>
          <Button
            ghost
            className="step-back-foward-btn"
            onClick={() => onNext()}
          >
            <StepForwardOutlined />
          </Button>
        </Col>
        <Col>
          <Button
            ghost
            className={`${isRepeat ? 'active' : ''}`}
            onClick={() => onRepeat()}
          >
            <RedoOutlined />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PlayerMediaControls;
