import client from 'axios';
import { apiBaseUrl } from '../constants';

const apiService = client.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: `Bearer ${
      localStorage.getItem('accessToken')
        ? localStorage.getItem('accessToken')
        : ''
    }`,
  },
});

apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken');
      window.location.assign('/#/login');
    }
    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong!'
    );
  }
);

export { apiService };
