import useAudioPlayer from './Audio/useAudioPlayer';
import PlayerMediaControls from './MediaControls';
import VolumeMediaControls from './VolumeControls';
import ProgressBar from './ProgressBar';
import SongInformation from './SongInformation';
import ToastNotification from '../global/ToastNotification';
import { useAuthentication } from '../../context/authContext';
import { apiService as axios } from '../../services/network';
import { audioCdnBaseUrl } from '../../constants';
import { debounce } from '../../utilities';

// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  playNext,
  playFirstSong,
  playPrevious,
  setRepeat,
  selectActivePlayer,
  setPlayerActive,
  selectPlayerQueue,
  selectNowPlaying,
  selectUpNext,
  selectRepeat,
} from '../../features/player/playerSlice';

const MusicPlayer = () => {
  const { userInfo } = useAuthentication();
  const dispatch = useDispatch();
  const queue = useSelector(selectPlayerQueue);
  const nowPlaying = useSelector(selectNowPlaying);
  const upNext = useSelector(selectUpNext);
  const isRepeat = useSelector(selectRepeat);
  const isPlayerActive = useSelector(selectActivePlayer);
  const audioPath = nowPlaying.audio
    ? `${audioCdnBaseUrl}${nowPlaying.audio}`
    : '';
  const playButtonDisabled = queue.length === 0;
  const onEndPlay = () => {
    if (upNext.length) {
      debounce(dispatch(playNext(nowPlaying)), 1000);
    }
    if (!upNext.length) {
      dispatch(setPlayerActive(false));
    }
  };
  const onRecordStreamCb = () => {
    if (!userInfo.twitchId.length || !nowPlaying) {
      return 0;
    }
    try {
      const body = {
        songId: nowPlaying.uipc,
        songTitle: nowPlaying.title,
        songArtist: nowPlaying.project_artist,
      };
      axios.post('/streaming', body);
    } catch (error) {
      console.error(error);
      ToastNotification({
        title: 'Error',
        message:
          'Unable to connect with Noizie API, please try logging in again.',
      });
    }
  };
  const {
    currentTime,
    duration,
    setClickedTime,
    currentVolume,
    setClickedVolume,
    setTriggerMute,
    setTriggerUnMute,
  } = useAudioPlayer({ onEnded: onEndPlay, onRecordStream: onRecordStreamCb });

  const handlePlayCallback = () => {
    if (!nowPlaying.audio) {
      dispatch(
        playFirstSong({
          userInformation: userInfo,
        })
      );
    }
    dispatch(setPlayerActive(!isPlayerActive));
  };

  const handlePlayNext = () => {
    if (nowPlaying.audio) {
      dispatch(
        playNext({
          nowPlaying,
          userInformation: userInfo,
        })
      );
    }
  };

  const handlePlayPrevious = () => {
    if (nowPlaying.audio) {
      dispatch(
        playPrevious({
          nowPlaying,
          userInformation: userInfo,
        })
      );
    }
  };

  const handleRepeat = () => {
    dispatch(setRepeat({ repeat: !isRepeat }));
  };

  return (
    <div className="audio-player">
      <audio src={audioPath} id="audio-tag">
        Your browser does not support the <code>audio</code> element. Please use
        the latest version of Chrome, Mozilla Firefox or Microsoft Edge.
      </audio>
      <div className="audio-player-control-wrapper">
        <VolumeMediaControls
          currentVolume={currentVolume}
          onVolumeUpdate={(volume) => setClickedVolume(volume)}
          onTriggerMute={setTriggerMute}
          onTriggerUnMute={setTriggerUnMute}
        />
        <SongInformation songInformation={nowPlaying} />
        <ProgressBar
          currentTime={currentTime}
          duration={duration}
          onTimeUpdate={(time) => setClickedTime(time)}
        />
        <PlayerMediaControls
          onPlayPause={handlePlayCallback}
          isPlaying={isPlayerActive}
          onNext={handlePlayNext}
          onPrevious={handlePlayPrevious}
          onRepeat={handleRepeat}
          isRepeat={isRepeat}
          playDisabled={playButtonDisabled}
        />
      </div>
    </div>
  );
};

export default MusicPlayer;
