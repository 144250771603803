import { useState, useEffect } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActivePlayer,
  selectCountAsPlayed,
  setCountAsPlayed,
} from '../../../features/player/playerSlice';

const useAudioPlayer = ({ onEnded, onRecordStream }) => {
  const [duration, setDuration] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [clickedTime, setClickedTime] = useState();
  const [currentVolume, setCurrentVolume] = useState();
  const [clickedVolume, setClickedVolume] = useState();
  const [triggerMute, setTriggerMute] = useState();
  const [triggerUnMute, setTriggerUnMute] = useState();
  const [previousVolume, setPreviousVolume] = useState();
  const [playbackTime, setPlaybackTime] = useState(0);
  const isPlayerActive = useSelector(selectActivePlayer);
  const countAsPlayed = useSelector(selectCountAsPlayed);
  const dispatch = useDispatch();

  useEffect(() => {
    const audioTag = document.getElementById('audio-tag');

    if (!audioTag) {
      return;
    }

    // audio state setters wrapper function
    const setAudioData = () => {
      setDuration(audioTag.duration);
      setCurrentTime(audioTag.currentTime);
      setCurrentVolume(audioTag.volume);
      setPlaybackTime(0);
    };

    const setAudioTime = (e) => {
      setCurrentTime(Math.ceil(audioTag.currentTime));

      if (currentTime >= duration) {
        setPlaybackTime(0);
        onEnded();
      }
    };

    const setAudioVolume = () => {
      setCurrentVolume(audioTag.volume);
    };

    // Update React state whenever user trigger DOM events
    audioTag.addEventListener('loadeddata', setAudioData);
    audioTag.addEventListener('timeupdate', setAudioTime);
    audioTag.addEventListener('volumechange', setAudioVolume);

    // Update Audio Tag on React state changes
    // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    if (isPlayerActive) {
      let playPromise = audioTag.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => console.log('Enjoy the music!'))
          .catch((error) => console.error(error.message));
      }
    } else {
      audioTag.pause();
    }

    if (clickedTime && clickedTime !== currentTime) {
      audioTag.currentTime = clickedTime;
      setClickedTime(null);
    }

    if (clickedVolume && clickedVolume !== currentVolume) {
      audioTag.volume = clickedVolume;
      setClickedVolume(null);
    }

    if (triggerMute) {
      const prevVolume = audioTag.volume;
      audioTag.volume = 0;
      setTriggerMute(null);
      setPreviousVolume(prevVolume);
    }

    if (triggerUnMute) {
      audioTag.volume = previousVolume;
      setTriggerUnMute(null);
      setPreviousVolume(null);
    }

    // effect cleanup
    return () => {
      audioTag.removeEventListener('loadeddata', setAudioData);
      audioTag.removeEventListener('timeupdate', setAudioTime);
      audioTag.removeEventListener('volumechange', setAudioVolume);
    };
  }, [
    isPlayerActive,
    clickedTime,
    currentTime,
    duration,
    clickedVolume,
    currentVolume,
    triggerMute,
    triggerUnMute,
    previousVolume,
    onEnded,
  ]);

  useEffect(() => {
    if (currentTime > 0) {
      setPlaybackTime((prevTime) => prevTime + 1);
    }
  }, [currentTime]);

  if (playbackTime === Math.ceil(duration * 0.3) && !countAsPlayed) {
    dispatch(setCountAsPlayed(true));

    if (onRecordStream) {
      onRecordStream();
    }
  }

  return {
    duration,
    currentTime,
    setClickedTime,
    currentVolume,
    setClickedVolume,
    setTriggerMute,
    setTriggerUnMute,
  };
};

export default useAudioPlayer;
