import { notification } from 'antd';

const ToastNotification = ({ title, message, duration = 4.5 }) => {
  notification[title.toLowerCase()]({
    message: title,
    description: message,
    duration: duration,
  });
};

export default ToastNotification;
