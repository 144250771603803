import { createSlice } from '@reduxjs/toolkit';
import { shuffleArray, debounce } from '../../utilities';
import { apiService as axios } from '../../services/network';

const initialState = {
  queue: [],
  shuffleQueue: [],
  shuffle: false,
  nowPlaying: {},
  upNext: [],
  history: [],
  repeat: false,
  playerActive: false,
  countAsPlayed: false,
};

const internals = {};

// Function used to post song information for twitch extension
// debounced to avoid posting when user skips tracks multiple times
internals.postSongInfoForExt = debounce(
  ({ songInformation = {}, userInformation = {} }) => {
    if (!userInformation.twitchId.length) {
      return;
    }
    try {
      const body = {
        songId: songInformation.uipc,
        songTitle: songInformation.title,
        songArtist: songInformation.project_artist,
        artwork: songInformation.artwork,
        twitchId: userInformation.twitchId,
      };
      axios.post('/messages', body);
    } catch (error) {
      console.error(error);
    }
  },
  2500
);

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    addToQueue(state, action) {
      const { ...songInformation } = action.payload;

      state.queue.push(songInformation);
      state.upNext.push(songInformation);
    },
    addMultipletoQueue(state, action) {
      const { songs } = action.payload;

      state.queue = [];
      state.upNext = [];

      songs.map((song) => {
        state.queue.push(song);
        state.upNext.push(song);

        return 0;
      });
    },
    removeFromQueue(state, action) {
      const { id: songId } = action.payload;
      const indexQueueDelete = state.queue.findIndex(
        (song) => song.uipc === songId
      );
      const indexupNextDelete = state.upNext.findIndex(
        (song) => song.uipc === songId
      );

      state.queue.splice(indexQueueDelete, 1);
      state.upNext.splice(indexupNextDelete, 1);
    },
    playFirstSong(state, action) {
      const { userInformation } = action.payload;
      const firstSong = { ...state.upNext.shift() };

      state.nowPlaying = firstSong;
      state.countAsPlayed = false;
      internals.postSongInfoForExt({
        songInformation: firstSong,
        userInformation,
      });
    },
    playNext(state, action) {
      const { nowPlaying, userInformation } = action.payload;

      if (state.upNext.length) {
        const nextSong = { ...state.upNext.shift() };

        state.history.unshift({ ...nowPlaying });
        state.nowPlaying = nextSong;
        state.countAsPlayed = false;
        internals.postSongInfoForExt({
          songInformation: nextSong,
          userInformation,
        });
      }

      if (state.upNext.length === 1 && state.repeat) {
        state.upNext = [...state.upNext, ...state.queue];
      }
    },
    playPrevious(state, action) {
      const { nowPlaying, userInformation } = action.payload;
      const indexQueue = state.queue.findIndex(
        (song) => song.uipc === nowPlaying.uipc
      );

      if (indexQueue > 0) {
        const previousSong = state.queue[indexQueue - 1];

        state.upNext.unshift({ ...nowPlaying });
        state.history.unshift({ ...nowPlaying });
        state.nowPlaying = previousSong;
        state.countAsPlayed = false;
        internals.postSongInfoForExt({
          songInformation: previousSong,
          userInformation,
        });
      }
    },
    shuffleQueue(state, action) {
      state.shuffle = !state.shuffle;

      if (state.shuffle) {
        state.shuffleQueue = shuffleArray(state.queue);
      } else {
        state.shuffleQueue = [];
      }
    },
    setRepeat(state, action) {
      const { repeat } = action.payload;

      state.repeat = repeat;
    },
    playSong(state, action) {
      const { songInformation, userInformation } = action.payload;

      state.queue.push(songInformation);
      state.nowPlaying = songInformation;
      state.countAsPlayed = false;
      internals.postSongInfoForExt({
        songInformation,
        userInformation,
      });
    },
    setPlayerActive(state, action) {
      state.playerActive = action.payload;
    },
    setCountAsPlayed(state, action) {
      state.countAsPlayed = action.payload;
    },
  },
});

export const {
  addToQueue,
  addMultipletoQueue,
  removeFromQueue,
  playNext,
  playFirstSong,
  playPrevious,
  shuffleQueue,
  setRepeat,
  playSong,
  setPlayerActive,
  setCountAsPlayed,
} = playerSlice.actions;

export default playerSlice.reducer;

export const selectActivePlayer = (state) => state.player.playerActive;

export const selectPlayerQueue = (state) => state.player.queue;

export const selectNowPlaying = (state) => state.player.nowPlaying;

export const selectUpNext = (state) => state.player.upNext;

export const selectRepeat = (state) => state.player.repeat;

export const selectCountAsPlayed = (state) => state.player.countAsPlayed;
