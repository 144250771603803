import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Empty, Breadcrumb } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
// Custom Componentes
import Loader from '../../global/Loader';
import SongInformation from '../../global/SongInformation';
import ActionsModalMenu from '../../global/ActionsModalMenu';
import { useAuthentication } from '../../../context/authContext';

// redux implementation
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllSongs,
  fetchSongsByTag,
  clearSongs,
  selectMoreSongs,
} from '../../../features/musicLibrary/musicLibrarySlice';

import { addToQueue } from '../../../features/player/playerSlice';

const DetailsList = () => {
  const songs = useSelector(selectAllSongs);
  const songStatus = useSelector((state) => state.musicLibrary.songs.status);
  const songsError = useSelector((state) => state.musicLibrary.songs.error);
  const hasMoreSongs = useSelector(selectMoreSongs);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useAuthentication();
  const { categoryId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearSongs());
    dispatch(
      fetchSongsByTag({
        tag: decodeURIComponent(categoryId),
        page: currentPage,
      })
    );

    setCurrentPage((prevState) => prevState + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, dispatch]);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedSong(null);
  };

  const handleOpenModal = (songInformation) => {
    setIsModalVisible(true);
    setSelectedSong(songInformation);
  };

  const handleAddSongToQueue = (songInformation) => {
    dispatch(addToQueue(songInformation));
  };

  const handleInfiniteOnLoad = () => {
    if (hasMoreSongs) {
      setIsLoading(true);

      dispatch(
        fetchSongsByTag({
          tag: categoryId,
          page: currentPage,
        })
      );
      setCurrentPage((prevState) => prevState + 1);
      setIsLoading(false);
    }
  };

  const renderScroller = () => {
    return (
      <div className="infinite-scroll-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={handleInfiniteOnLoad}
          hasMore={!isLoading && hasMoreSongs}
          useWindow={false}
        >
          {songs.map((song) => (
            <SongInformation
              key={song.uipc}
              onShowModal={handleOpenModal}
              songInformation={song}
              userInformation={userInfo}
            />
          ))}
          {isLoading && hasMoreSongs && (
            <div className="loading-container">
              <Loader />
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
  };

  let content;

  if (songStatus === 'loading') {
    content = <Loader />;
  } else if (songStatus === 'succeeded') {
    content = songs.length ? (
      renderScroller()
    ) : (
      <Empty
        className="mt-2rem"
        description={
          <span className="txt-center">
            Sorry, it appears no songs were assigned to this genre.
          </span>
        }
      />
    );
  } else if (songStatus === 'failed') {
    content = (
      <p className="error-message txt-center">{`${songsError}, please refresh and try again.`}</p>
    );
  }

  return (
    <div className="category-details">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/browse/">Library</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{decodeURIComponent(categoryId)}</Breadcrumb.Item>
      </Breadcrumb>
      {content}
      <Modal
        width={300}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        centered={true}
        wrapClassName="library-action-menu-modal"
      >
        {selectedSong && (
          <>
            <h2 className="txt-center">{selectedSong.title}</h2>
            <p className="txt-center">
              {`${selectedSong.project_artist} - ${selectedSong.project_title}`}
            </p>
          </>
        )}
        <ActionsModalMenu
          onAddQueueHandler={handleAddSongToQueue}
          selectedSong={selectedSong}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default DetailsList;
