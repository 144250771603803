import { Modal, Row, Col } from 'antd';
import { WarningFilled } from '@ant-design/icons';

const CancelSubscriptionModal = ({
  isModalProcessing,
  isModalVisible,
  onCancel,
  handleModalConfirm,
}) => {
  return (
    <Modal
      width={450}
      cancelText="No"
      centered={true}
      confirmLoading={isModalProcessing}
      closable={false}
      okText="Yes"
      onCancel={onCancel}
      onOk={handleModalConfirm}
      wrapClassName="library-action-menu-modal"
      visible={isModalVisible}
    >
      <Row align="middle" justify="center">
        <Col span={2} className="txt-center">
          <WarningFilled
            className="warning-icon"
            style={{ color: '#ffcc00' }}
          />
        </Col>
      </Row>
      <p className="cancel-subscription-paragraph txt-center">
        You are about to cancel your subscription, you will be able to continue
        to use the Noizie music player until the end of your current
        subscription period.
        <br></br>
        <br></br>
        <b>Are you sure you want to proceed?</b>
      </p>
    </Modal>
  );
};

export default CancelSubscriptionModal;
