import { Row, Col } from 'antd';

import '../../../styles/components/SongInformation.scss';

const SongInformation = (props) => {
  const { title: songName = '', artist = '' } = props.songInformation;

  return (
    <Row justify="center" align="middle" className="song-information">
      <Col span={20}>
        <h3 className="song-artist">{artist}</h3>
      </Col>
      <Col span={20}>
        <h2 className="song-name" title={songName.length > 21 ? songName : ''}>
          {songName}
        </h2>
      </Col>
    </Row>
  );
};

export default SongInformation;
