import { useEffect } from 'react';
import { Row, Col, Empty, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import Loader from '../../global/Loader';
import ToastNotification from '../../global/ToastNotification';

import musicNoteSvg from '../../../assets/icons/music-note.svg';

// redux implementation
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllTags,
  fetchTags,
  clearSongs,
} from '../../../features/musicLibrary/musicLibrarySlice';

const ListPanel = () => {
  const dispatch = useDispatch();
  const tags = useSelector(selectAllTags);
  const tagStatus = useSelector((state) => state.musicLibrary.tags.status);
  const tagError = useSelector((state) => state.musicLibrary.tags.error);
  const tagDefaultImage =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWPIz8//DwAE6wJN/MoNKAAAAABJRU5ErkJggg==';

  useEffect(() => {
    if (tagStatus === 'idle') {
      dispatch(fetchTags());
    }
    dispatch(clearSongs());
  }, [tagStatus, dispatch]);

  useEffect(() => {
    if (tagError) {
      ToastNotification({
        title: 'Error',
        message: tagError,
      });
    }
  }, [tagError]);

  let content;

  if (tagStatus === 'loading') {
    content = <Loader />;
  } else if (tagStatus === 'succeeded') {
    content = tags.length ? (
      <Row className="tags-container" gutter={[16, 10]}>
        {tags.map((tag) => (
          <Col key={tag._id} span={8}>
            <Link to={`/browse/${encodeURIComponent(tag.name.toLowerCase())}`}>
              <figure className="category-img-wrapper pr txt-center">
                <img
                  className="img-responsive category-img"
                  src={tag.imageUrl ? tag.imageUrl : tagDefaultImage}
                  alt=""
                />
                {!tag.imageUrl && (
                  <img className="note-icon" src={musicNoteSvg} alt="" />
                )}
                <figcaption>{tag.name}</figcaption>
              </figure>
            </Link>
          </Col>
        ))}
      </Row>
    ) : (
      <Empty
        className="mt-2rem"
        description={
          <span className="txt-center">
            Sorry, it appears no tags were created. Please contact support.
          </span>
        }
      />
    );
  } else if (tagStatus === 'failed') {
    content = (
      <p className="txt-center">{`${tagError}, please refresh and try again.`}</p>
    );
  }

  return (
    <div className="categories">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Library</Breadcrumb.Item>
      </Breadcrumb>
      {content}
    </div>
  );
};

export default ListPanel;
