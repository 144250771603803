import {
  useEffect,
  createContext,
  useContext,
  useReducer,
  useState,
} from 'react';
import Loader from '../components/global/Loader';
import { setCookie, deleteCookie, setInfoToLocalStorage } from '../utilities';
import { apiService as axios } from '../services/network';

const initialState = {
  isUserAuth: false,
  isInitialised: false,
  userInfo: {},
  isRedirectingLogin: false,
};

const setSessionToken = (accessToken) => {
  if (accessToken) {
    setInfoToLocalStorage({ accessToken });
    axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isUserAuth, userInfo } = action.payload;

      return {
        ...state,
        isUserAuth,
        isInitialised: true,
        userInfo,
      };
    }
    case 'LOGIN': {
      const { userInfo } = action.payload;

      return {
        ...state,
        isUserAuth: true,
        userInfo,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isUserAuth: false,
        userInfo: {},
      };
    }
    case 'UPDATEUSER': {
      const { userInfo } = action.payload;

      return {
        ...state,
        userInfo,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthenticationContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
});

export const AuthenticationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isRedirectingLogin, setIsRedirectingLogin] = useState(false);

  const getUserInfo = async () => {
    try {
      const tokenInStorage = localStorage.getItem('accessToken');

      if (tokenInStorage) {
        setSessionToken(tokenInStorage);
        const { data } = await axios.get('/auth/session');
        const {
          name = '',
          clientId,
          picture = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWPIz8//DwAE6wJN/MoNKAAAAABJRU5ErkJggg==',
          googleId = '',
          twitchId = '',
          subscriptionId = '',
          subscriptionStatus = '',
          cancel_at_period_end = false,
        } = data;

        dispatch({
          type: 'INIT',
          payload: {
            isUserAuth: true,
            userInfo: {
              name,
              clientId,
              picture,
              googleId,
              twitchId,
              subscriptionId,
              subscriptionStatus,
              cancel_at_period_end,
            },
          },
        });
      } else {
        dispatch({
          type: 'INIT',
          payload: {
            isUserAuth: false,
            userInfo: {},
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INIT',
        payload: {
          isUserAuth: false,
          userInfo: {},
        },
      });
    }
  };

  const login = async ({ params, provider }) => {
    setIsRedirectingLogin(true);
    const requestUrl =
      provider === 'google'
        ? `/auth/google/redirect${params}`
        : `/auth/twitch/redirect${params}`;
    const { data } = await axios.get(requestUrl);
    const {
      user = {},
      hasGoogleAccount = false,
      hasTwitchAccount = false,
    } = data;
    let { accessToken = '' } = data;
    const {
      name = '',
      clientId,
      picture = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWPIz8//DwAE6wJN/MoNKAAAAABJRU5ErkJggg==',
      googleId = '',
      twitchId = '',
      subscriptionId = '',
      subscriptionStatus = '',
      cancel_at_period_end = false,
    } = user;

    // App confirm depends on auth provider.
    if (hasGoogleAccount) {
      const googleConfirm = await axios.patch('/auth/twitch/confirm', { user });

      accessToken = googleConfirm.data.accessToken;
    } else if (hasTwitchAccount) {
      const twitchConfirm = await axios.patch('/auth/google/confirm', { user });

      accessToken = twitchConfirm.data.accessToken;
    }

    setSessionToken(accessToken);
    setCookie('__nzLoggedIn', true, 14);

    dispatch({
      type: 'LOGIN',
      payload: {
        userInfo: {
          name,
          clientId,
          picture,
          googleId,
          twitchId,
          subscriptionId,
          subscriptionStatus,
          cancel_at_period_end,
        },
      },
    });
    setIsRedirectingLogin(false);
  };

  const logout = () => {
    setSessionToken(null);
    deleteCookie('__nzLoggedIn');
    dispatch({ type: 'LOGOUT' });
  };

  const updateUser = ({ userInfo }) => {
    dispatch({
      type: 'UPDATEUSER',
      payload: {
        userInfo,
      },
    });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  if (!state.isInitialised) {
    return <Loader isFullscreen />;
  }

  return (
    <AuthenticationContext.Provider
      value={{
        ...state,
        isRedirectingLogin,
        logout,
        login,
        updateUser,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      'useAuthentication must be call within AuthenticationProvider'
    );
  }

  return context;
}

export function useIsUserAuth() {
  const context = useAuthentication();

  if (context === undefined) {
    throw new Error(
      'useAuthentication must be call within AuthenticationProvider'
    );
  }

  return context.isUserAuth;
}
