import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Modal, Row, Col, Image } from 'antd';
import { stripeFEKey } from '../../../constants';
import AddCreditCardForm from './Form';

import stripe from '../../../assets/logos/powered-stripe.svg';

const stripePromise = loadStripe(stripeFEKey);

const AddCreditCardModal = (props) => {
  const {
    isModalVisible = false,
    isModalProcessing = false,
    handleModalConfirm = () => {},
    handleCloseModal = () => {},
    setupIntent,
    setSetupIntent,
    getWallet,
  } = props;

  return (
    <Modal
      width={450}
      cancelText="No"
      centered={true}
      confirmLoading={isModalProcessing}
      okText="Yes"
      onCancel={handleCloseModal}
      onOk={handleModalConfirm}
      wrapClassName="library-action-menu-modal add-credit-card-modal"
      visible={isModalVisible}
      footer={null}
    >
      <h4 className="modal-ttl txt-center">
        Please enter your new credit card information.
      </h4>
      <Elements stripe={stripePromise}>
        <AddCreditCardForm
          handleCloseModal={handleCloseModal}
          handleModalConfirm={handleModalConfirm}
          setupIntent={setupIntent}
          setSetupIntent={setSetupIntent}
          getWallet={getWallet}
        />
      </Elements>
      <Row align="middle" justify="end">
        <Col pull={1}>
          <Image
            src={stripe}
            className="stripe-logo"
            alt="Powered by Stripe"
            preview={false}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default AddCreditCardModal;
