import { appDomain, appEnv } from '../constants';

const internals = {};

internals.shuffleArray = (array) => {
  const shuffledArray = [...array];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

internals.debounce = (func, delay, immediate) => {
  let timerId;
  return (...args) => {
    const boundFunc = func.bind(this, ...args);
    clearTimeout(timerId);
    if (immediate && !timerId) {
      boundFunc();
    }
    const calleeFunc = immediate
      ? () => {
          timerId = null;
        }
      : boundFunc;
    timerId = setTimeout(calleeFunc, delay);
  };
};

internals.setInfoToLocalStorage = (information = {}) => {
  Object.keys(information).forEach((key) => {
    if (typeof information[key] === 'object') {
      localStorage.setItem(`${key}`, JSON.stringify(information[key]));
    } else {
      localStorage.setItem(`${key}`, information[key]);
    }
  });
};

internals.setCookie = (name, value, days) => {
  const assign = `${name}=${escape(value)};`;
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()};`;
  const path = 'path=/;';

  if (appEnv === 'dev') {
    document.cookie = assign + expires + path;
  } else {
    document.cookie = assign + expires + path + appDomain;
  }
};

internals.getCookie = (name) =>
  document.cookie
    .split(';')
    .some((cName) => cName.trim().startsWith(`${name}=`));

internals.deleteCookie = (name) => {
  const d = new Date();
  d.setTime(d.getTime() - 1000 * 60 * 60 * 24);
  const expires = `expires=${d.toGMTString()}`;

  window.document.cookie = `${name}=; ${expires}`;
};

internals.capitalize = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const {
  shuffleArray,
  debounce,
  setInfoToLocalStorage,
  setCookie,
  getCookie,
  deleteCookie,
  capitalize,
} = internals;
