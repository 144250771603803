import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';

import '../../styles/404.scss';

const NotFound = () => {
  const history = useHistory();

  return (
    <div className="not-found-container txt-center panel-box-shadow">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, we can't find what you were looking for."
        extra={
          <Button
            className="go-back-btn"
            shape="round"
            type="primary"
            onClick={() => history.goBack()}
          >
            Previous Page
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
