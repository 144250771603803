import { useState, useEffect } from 'react';
import { Input, Modal, Empty, Breadcrumb, Affix } from 'antd';
// Custom Componentes
import SongInformation from '../global/SongInformation';
import ActionsModalMenu from '../global/ActionsModalMenu';
import { useAuthentication } from '../../context/authContext';
import ToastNotification from '../global/ToastNotification';
import SkeletonLoader from '../global/SkeletonLoader';

import '../../styles/components/SearchPanel.scss';

// redux implementation
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSearchSongsByTagResults,
  searchSongsByParam,
  clearSearchByTagResults,
} from '../../features/search/searchSlice';

import { addToQueue } from '../../features/player/playerSlice';

const { Search } = Input;

const SearchPanel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const dispatch = useDispatch();
  const songs = useSelector(selectSearchSongsByTagResults);
  const songStatus = useSelector(
    (state) => state.search.searchSongsByTag.status
  );
  const songsError = useSelector(
    (state) => state.search.searchSongsByTag.error
  );
  const { userInfo } = useAuthentication();
  let content;
  const [container, setContainer] = useState(null);

  useEffect(() => {
    dispatch(clearSearchByTagResults());
  }, [dispatch]);

  useEffect(() => {
    if (songsError) {
      ToastNotification({
        title: 'Error',
        message: songsError,
      });
    }
  }, [songsError]);

  const handleOpenModal = (songInformation) => {
    setIsModalVisible(true);
    setSelectedSong(songInformation);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedSong(null);
  };

  const handleOnSearch = (value) => {
    if (!value.length) {
      dispatch(clearSearchByTagResults());

      return;
    }
    dispatch(
      searchSongsByParam({
        param: value,
      })
    );
  };

  const handleAddSongToQueue = (songInformation) => {
    dispatch(addToQueue(songInformation));
  };

  if (songStatus === 'loading') {
    content = <SkeletonLoader rows={5} />;
  } else if (songStatus === 'succeeded') {
    content = songs.length ? (
      songs.map((song) => (
        <SongInformation
          key={song.uipc}
          onShowModal={handleOpenModal}
          songInformation={song}
          userInformation={userInfo}
        />
      ))
    ) : (
      <Empty
        description={
          <span className="txt-center no-results">
            No results found. <br></br>
            <small>
              Please check your search keywords spelling or try different
              keywords.
            </small>
          </span>
        }
      />
    );
  } else if (songStatus === 'failed') {
    content = (
      <div className="error-message txt-center">{`${songsError}, please refresh and try again.`}</div>
    );
  }

  return (
    <div className="search-panel app-panel" ref={setContainer}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Search</Breadcrumb.Item>
      </Breadcrumb>
      <Affix target={() => container}>
        <Search
          className="search-input"
          placeholder="What are you looking for?"
          allowClear
          enterButton="Search"
          size="middle"
          onSearch={handleOnSearch}
          loading={songStatus === 'loading'}
        />
      </Affix>
      <div className="search-results">{content}</div>
      <Modal
        width={300}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        centered={true}
        wrapClassName="library-action-menu-modal"
      >
        {selectedSong && (
          <>
            <h2 className="mt-2rem txt-center">{selectedSong.title}</h2>
            <p className="txt-center">
              {`${selectedSong.project_artist} - ${selectedSong.project_title}`}
            </p>
          </>
        )}
        <ActionsModalMenu
          onAddQueueHandler={handleAddSongToQueue}
          selectedSong={selectedSong}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
};

export default SearchPanel;
