import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal, Menu, Button, Empty, Tooltip, Breadcrumb } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
// Custom Componentes
import Loader from '../../global/Loader';
import SongInformation from '../../global/SongInformation';
import { useAuthentication } from '../../../context/authContext';

// redux implementation
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPlaylistSongs,
  fetchPlaylistbyId,
  updatePlaylist,
} from '../../../features/playlists/playlistSlice';
import {
  addToQueue,
  addMultipletoQueue,
  playFirstSong,
  setPlayerActive,
  selectActivePlayer,
} from '../../../features/player/playerSlice';

const { confirm } = Modal;

const DetailsList = () => {
  const { playlistId } = useParams();
  const dispatch = useDispatch();
  const playlistDetail = useSelector(selectPlaylistSongs);
  const playlistDetailStatus = useSelector(
    (state) => state.playlist.playlistDetail.status
  );
  const playlistDetailError = useSelector(
    (state) => state.playlist.playlistDetail.error
  );
  const isPlayerActive = useSelector(selectActivePlayer);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const { userInfo } = useAuthentication();

  useEffect(() => {
    if (playlistDetailStatus === 'idle') {
      dispatch(fetchPlaylistbyId({ playlistId }));
    }
  }, [playlistDetailStatus, dispatch, playlistId]);

  const handleOpenModal = (songInformation) => {
    setIsModalVisible(true);
    setSelectedSong(songInformation);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedSong(null);
  };

  const handleAddSongToQueue = (songInformation) => {
    dispatch(addToQueue(songInformation));
  };

  const handlePlayAll = () => {
    if (playlistDetail.songs.length) {
      dispatch(addMultipletoQueue({ songs: playlistDetail.songs }));
      dispatch(
        playFirstSong({
          userInformation: userInfo,
        })
      );
      if (!isPlayerActive) {
        dispatch(setPlayerActive(true));
      }
    }
  };

  const handleOnConfirmDeletion = () => {
    const updatedPlaylistSongs = playlistDetail.songs.filter(
      (song) => song.uipc !== selectedSong.uipc
    );
    const updatedPlaylistTags = [
      ...new Set(...updatedPlaylistSongs.map((song) => song.tags)),
    ];
    const updatedPlaylist = {
      ...playlistDetail,
      songs: updatedPlaylistSongs.map((song) => song.uipc),
      tags: updatedPlaylistTags,
    };

    dispatch(updatePlaylist(updatedPlaylist));
    handleCloseModal();
  };

  const renderModal = ({ action, onOkHandler }) =>
    confirm({
      title: `Confirm ${action}`,
      content: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: onOkHandler,
      centered: true,
      className: 'library-action-menu-modal',
      width: '275px',
    });

  const onClickMenu = (event) => {
    if (event.key === 'addQueue') {
      renderModal({
        action: 'Add to Queue',
        onOkHandler: () => handleAddSongToQueue(selectedSong),
      });
    } else if (event.key === 'delete') {
      renderModal({
        action: 'Removal',
        onOkHandler: handleOnConfirmDeletion,
      });
    }
  };

  let content;

  if (playlistDetailStatus === 'loading') {
    content = <Loader />;
  } else if (playlistDetailStatus === 'succeeded') {
    content = playlistDetail.songs.length ? (
      playlistDetail.songs.map((song) => (
        <SongInformation
          key={song.uipc}
          onShowModal={handleOpenModal}
          songInformation={song}
          userInformation={userInfo}
        />
      ))
    ) : (
      <Empty
        description={
          <span className="txt-center">This playlist is currently empty.</span>
        }
      />
    );
  } else if (playlistDetailStatus === 'failed') {
    content = (
      <p className="songsError txt-center">{`${playlistDetailError}, please refresh and try again.`}</p>
    );
  }

  return (
    <div className="playlist-details">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/playlists/">Playlists</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Detail</Breadcrumb.Item>
      </Breadcrumb>
      {playlistDetail && (
        <>
          <h2 className="panel-ttl capitalize txt-left">
            {playlistDetail.title}
          </h2>
          <p className="txt-left">{playlistDetail.description}</p>
          <Tooltip title="Play">
            <Button
              className="play-all-btn"
              icon={<CaretRightOutlined />}
              size="small"
              shape="circle"
              onClick={handlePlayAll}
            />
          </Tooltip>
        </>
      )}
      {content}
      <Modal
        width={300}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        centered={true}
        wrapClassName="library-action-menu-modal"
      >
        {selectedSong && (
          <>
            <h2 className="mt-1rem txt-center">{selectedSong.title}</h2>
            <p className="txt-center">
              {`${selectedSong.project_artist} - ${selectedSong.project_title}`}
            </p>
          </>
        )}
        <Menu theme="dark" selectable={false} onClick={onClickMenu}>
          <Menu.Item key="addQueue">Add to queue</Menu.Item>
          <Menu.Item key="delete">Remove from this playlist</Menu.Item>
        </Menu>
      </Modal>
    </div>
  );
};

export default DetailsList;
