import { Col, Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const PlayListInformation = (props) => {
  const { playlistInfo, onShowModal, uri } = props;

  return (
    <>
      <Col span={20}>
        <Link className="playlist-link" to={`${uri}/${playlistInfo.uipc}`}>
          <p className="playlist-title">{playlistInfo.title}</p>
          <span className="playlist-information">
            {playlistInfo.description}
          </span>
        </Link>
      </Col>
      <Col span={4}>
        <Button
          ghost
          className="action-btn"
          onClick={() => onShowModal(playlistInfo)}
        >
          <EllipsisOutlined rotate={90} />
        </Button>
      </Col>
    </>
  );
};

export default PlayListInformation;
