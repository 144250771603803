import { useRef } from 'react';
import { intervalToDuration } from 'date-fns';

import '../../../styles/components/ProgressBar.scss';

const ProgressBar = (props) => {
  const { duration, currentTime, onTimeUpdate } = props;
  const progressBarRef = useRef(null);
  const currentTimePercentage = (currentTime / duration) * 100;

  const calculateClickedTime = (e) => {
    const clickPositionInPage = e.pageX;
    const barStart =
      progressBarRef.current.getBoundingClientRect().left + window.scrollX;
    const barWidth = progressBarRef.current.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;

    return timePerPixel * clickPositionInBar;
  };

  const handleTimeDrag = (e) => {
    // nothing should happen if its not a left click
    if (e.button !== 0) {
      return;
    }
    onTimeUpdate(calculateClickedTime(e));

    const updateTimeOnMove = (eMove) => {
      onTimeUpdate(calculateClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  };

  const formatDuration = (duration) => {
    const durationObj = intervalToDuration({
      start: 0,
      end: duration * 1000,
    });
    const { minutes, seconds } = durationObj;

    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="progress-bar-container">
      {currentTime && (
        <span className="time-display txt-center">
          {formatDuration(currentTime)}
        </span>
      )}
      <div
        className="progress-bar"
        ref={progressBarRef}
        style={{
          background: `linear-gradient(to right, #193f7c ${currentTimePercentage}%, white 0)`,
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span
          className="progress-bar-knob"
          style={{ left: `${currentTimePercentage - 2}%` }}
        />
      </div>
      {duration && (
        <span className="time-display txt-center">
          {formatDuration(duration)}
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
