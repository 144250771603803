import { Route, Switch } from 'react-router-dom';
import ListPanel from './List/';
import DetailsList from './Detail/';

import '../../styles/components/PlaylistPanel.scss';

const Playlists = ({ match }) => {
  return (
    <div className="playlist-panel app-panel">
      <Switch>
        <Route exact path={match.path} component={ListPanel} />
        <Route
          exact
          path={`${match.path}/:playlistId`}
          component={DetailsList}
        />
      </Switch>
    </div>
  );
};

export default Playlists;
