import { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import FormField from './FormElements/FormField';
import CustomRadioButton from './FormElements/CustomRadioButton';
import StripeErrorMessage from '../global/FormElements/StripeErrorMessage';
import ResultMessage from './FormElements/ResultMessage';
import { apiService as axios } from '../../services/network';
import Loader from '../global/Loader';
import { useAuthentication } from '../../context/authContext';
import { CARD_OPTIONS } from '../../constants';

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentProduct, setPaymentProduct] = useState([]);
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
    subscription: '',
  });
  const { userInfo, updateUser } = useAuthentication();
  const history = useHistory();

  useEffect(() => {
    getPaymentProducts();

    return () => setPaymentProduct([]);
  }, [setPaymentProduct]);

  useEffect(() => {
    if (paymentMethod) {
      // Once payment is process we need to redirect to the app
      setTimeout(() => {
        history.push('/');
      }, 4500);
    }
  }, [paymentMethod, history]);

  const getPaymentProducts = async () => {
    try {
      const { data } = await axios.get('/payment/products');
      const {
        status,
        data: { data: pricesData },
      } = await axios.get(`/payment/products/${data.data[0].id}/prices`);

      // TODO: create a section to select tier within the payment form
      // right now we only have one tier
      // in the future this might change

      if (status === 200) {
        setPaymentProduct(pricesData.reverse());
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderSubscriptions = () => {
    if (paymentProduct.length) {
      return paymentProduct.map((product, index) => (
        <CustomRadioButton
          key={product.id}
          value={product.id}
          id={`${product.id}-radio-btn`}
          name="subscription-plan"
          label={`${product.recurring.interval_count} ${product.recurring.interval} subscription`}
          selectedOption={billingDetails.subscription}
          required={index === 0}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              subscription: e.target.value,
            });
          }}
        />
      ));
    }
    return <Loader />;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: billingDetails.email,
        name: billingDetails.name,
        phone: billingDetails.phone,
      },
    });

    if (payload.error) {
      setError(payload.error);
    } else {
      const { paymentMethod } = payload;
      const { data } = await axios.post('/payment/subscriptions', {
        payment_method: paymentMethod.id,
        plan: billingDetails.subscription,
        email: billingDetails.email,
        phone: billingDetails.phone,
      });
      const updatedUserInfo = {
        ...userInfo,
        subscriptionId: data.id,
        subscriptionStatus: data.status,
        cancel_at_period_end: data.cancel_at_period_end,
      };

      setPaymentMethod(payload.paymentMethod);
      // Update user information on AUTH context
      updateUser({ userInfo: updatedUserInfo });
    }
    setProcessing(false);
  };

  return paymentMethod ? (
    <ResultMessage
      paymentMethod={paymentMethod}
      paymentProduct={paymentProduct}
      selectedSubscription={billingDetails.subscription}
    />
  ) : (
    <form onSubmit={handleSubmit}>
      <fieldset className="form-group">
        <legend>Contact Information</legend>
        <FormField
          label="Name"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              name: e.target.value,
            });
          }}
        />
        <FormField
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              email: e.target.value,
            });
          }}
        />
        <FormField
          label="Phone"
          id="phone"
          type="tel"
          placeholder="(941) 555-0123"
          required
          autoComplete="tel"
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              phone: e.target.value,
            });
          }}
        />
      </fieldset>
      <fieldset className="form-group">
        <legend>Choose a Subscription:</legend>
        {renderSubscriptions()}
      </fieldset>
      <fieldset className="form-group">
        <legend>Credit Card</legend>
        <CardElement
          options={CARD_OPTIONS}
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {error && <StripeErrorMessage>{error.message}</StripeErrorMessage>}
      <button
        className={`submit-btn ${error ? 'error' : ''}`}
        type="submit"
        disabled={processing || !stripe}
      >
        {processing ? 'Processing...' : 'Pay'}
      </button>
    </form>
  );
};

export default PaymentForm;
