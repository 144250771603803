import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PaymentIcon from '../../global/PaymentIcon';

const CrediCardDropdown = ({
  type,
  walletInformation = [],
  handleMenuClick,
  defaultPaymentMethod,
}) => {
  if (walletInformation.length) {
    const menu = (
      <Menu theme="dark" onClick={(e) => handleMenuClick(e.key, type)}>
        {walletInformation.map((paymentMethod) => (
          <Menu.Item
            key={paymentMethod.id}
            disabled={paymentMethod.id === defaultPaymentMethod}
          >
            <PaymentIcon type={paymentMethod.card.brand} />
            {` XXXX XXXX XXXX ${paymentMethod.card.last4}`}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown
        className="credit-card-dropdown"
        overlay={menu}
        trigger={['click']}
      >
        <Button>
          Select a credit card <DownOutlined />
        </Button>
      </Dropdown>
    );
  }

  return <>Loading...</>;
};

export default CrediCardDropdown;
