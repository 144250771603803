import { Fragment } from 'react';
import { Skeleton } from 'antd';

const SkeletonLoader = ({ rows = 1 }) => {
  const array = Array.from(Array(rows).keys());

  return array.map((row) => (
    <Fragment key={row}>
      <Skeleton.Button
        active
        style={{ marginTop: 15, marginBottom: 15 }}
        shape="circle"
      />
      <Skeleton.Input
        active
        style={{ width: 200, marginTop: 15, marginLeft: 15 }}
      />
    </Fragment>
  ));
};

export default SkeletonLoader;
