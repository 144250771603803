import { Route, Switch } from 'react-router-dom';
import ListPanel from './List/';
import DetailsList from './Detail/';

import '../../styles/components/MusicLibrary.scss';

const MusicLibrary = ({ match }) => {
  return (
    <div className="music-library-panel app-panel">
      <Switch>
        <Route exact path={match.path} component={ListPanel} />
        <Route
          exact
          path={`${match.path}/:categoryId`}
          component={DetailsList}
        />
      </Switch>
    </div>
  );
};

export default MusicLibrary;
