export const stripeFEKey = process.env.REACT_APP_STRIPE_KEY;

export const apiBaseUrl = process.env.REACT_APP_API_ENDPOINT;

export const appDomain = process.env.REACT_APP_DOMAIN;

export const appEnv = process.env.REACT_APP_ENV;

export const audioCdnBaseUrl =
  'https://ponaudio.sfo2.cdn.digitaloceanspaces.com/';

export const albumArtCdnBaseUrl =
  'https://ponimages.sfo2.cdn.digitaloceanspaces.com/';

export const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: 400,
      fontFamily: 'Oxygen, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#c4151b',
      color: '#c4151b',
    },
  },
};
