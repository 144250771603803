import { useState, useEffect } from 'react';
import { Row, Button, Modal, Drawer, Empty, Tooltip, Breadcrumb } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Custom Components
import ModalMenu from './ModalMenu';
import PlaylistInformation from './PlaylistInformation';
import CreatePlaylistForm from './CreatePlaylistForm';
import Loader from '../../global/Loader';
import { useAuthentication } from '../../../context/authContext';
import ToastNotification from '../../global/ToastNotification';

// redux implementation
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllPlaylists,
  selectAllPlaylists,
  createPlaylist,
  deletePlaylist,
  updatePlaylist,
  clearPlaylistDetail,
} from '../../../features/playlists/playlistSlice';

const ListPanel = ({ match }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPlaylist, setselectedPlaylist] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const playlists = useSelector(selectAllPlaylists);
  const playlistsStatus = useSelector(
    (state) => state.playlist.playlists.status
  );
  const playlistError = useSelector((state) => state.playlist.playlists.error);
  const { userInfo } = useAuthentication();

  useEffect(() => {
    if (playlistsStatus === 'idle') {
      dispatch(fetchAllPlaylists());
    }
    dispatch(clearPlaylistDetail());
  }, [playlistsStatus, dispatch]);

  useEffect(() => {
    if (playlistError) {
      ToastNotification({
        title: 'Error',
        message: playlistError,
      });
    }
  }, [playlistError]);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setselectedPlaylist(null);
  };

  const handleOpenModal = (playlistInformation) => {
    setIsModalVisible(true);
    setselectedPlaylist(playlistInformation);
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleDeletePlaylist = (playlistInformation) => {
    dispatch(
      deletePlaylist({
        playlistInfo: playlistInformation,
      })
    );
    setIsModalVisible(false);
  };

  const handleUpdatePlaylist = (playlistInformation) => {
    dispatch(
      updatePlaylist({
        playlistInfo: playlistInformation,
      })
    );
    setIsModalVisible(false);
  };

  let content;

  if (playlistsStatus === 'loading') {
    content = <Loader />;
  } else if (playlistsStatus === 'succeeded') {
    content = playlists.length ? (
      <Row align="middle" gutter={[16, 16]}>
        {playlists.map((playlist) => (
          <PlaylistInformation
            key={playlist.uipc}
            onShowModal={handleOpenModal}
            playlistInfo={playlist}
            uri={match.path}
          />
        ))}
      </Row>
    ) : (
      <Empty
        className="mt-2rem"
        description={
          <span className="txt-center">
            You haven't created any playlist yet.
          </span>
        }
      >
        <Button
          onClick={() => setIsDrawerVisible(true)}
          className="create-playlist-btn"
          shape="round"
          size="middle"
        >
          Create one now
        </Button>
      </Empty>
    );
  } else if (playlistsStatus === 'failed') {
    content = (
      <p className="error-message txt-center">{`${playlistError}, please refresh and try again.`}</p>
    );
  }

  return (
    <div className="playlists">
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Playlists</Breadcrumb.Item>
      </Breadcrumb>
      <Tooltip title="Create Playlist">
        <Button
          onClick={() => setIsDrawerVisible(true)}
          icon={<PlusOutlined />}
          className="create-playlist-btn"
          shape="circle"
        />
      </Tooltip>
      {content}
      <Modal
        width={300}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        centered={true}
        wrapClassName="library-action-menu-modal"
      >
        {selectedPlaylist && (
          <>
            <h2 className="txt-center">{selectedPlaylist.title}</h2>
            <p className="txt-center">{selectedPlaylist.description}</p>
          </>
        )}
        <ModalMenu
          onConfirmDeletion={handleDeletePlaylist}
          onSubmitUpdate={handleUpdatePlaylist}
          playlistInformation={selectedPlaylist}
        />
      </Modal>
      <Drawer
        className="playlist-drawer"
        placement="right"
        title="Create Playlist"
        onClose={handleCloseDrawer}
        visible={isDrawerVisible}
      >
        <CreatePlaylistForm
          isDrawerVisible={isDrawerVisible}
          createPlaylist={createPlaylist}
          handleCloseDrawer={handleCloseDrawer}
          userObject={userInfo}
        />
      </Drawer>
    </div>
  );
};

export default ListPanel;
