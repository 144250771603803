import { Image } from 'antd';
import { useSelector } from 'react-redux';
import { selectNowPlaying } from '../../../features/player/playerSlice';
import Loader from '../../global/Loader';
import { albumArtCdnBaseUrl } from '../../../constants';

const AlbumArt = () => {
  const nowPlaying = useSelector(selectNowPlaying);
  const defaultImage =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWPIz8//DwAE6wJN/MoNKAAAAABJRU5ErkJggg==';
  const albumArt = nowPlaying.artwork
    ? `${albumArtCdnBaseUrl}${nowPlaying.artwork.medium}`
    : defaultImage;
  const altText = nowPlaying.artist
    ? `${nowPlaying.artist}-${nowPlaying.title}`
    : 'album-art';

  return (
    <div className="album-art-wrapper">
      <Image
        className="album-art"
        alt={altText}
        src={albumArt}
        fallback={defaultImage}
        preview={false}
        width="100%"
        placeholder={<Loader />}
      />
    </div>
  );
};

export default AlbumArt;
