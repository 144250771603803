import { Row, Col, Button, Image } from 'antd';
import { useEffect, useCallback } from 'react';
import Icon from '@ant-design/icons';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { useAuthentication } from '../../context/authContext';
import Loader from '../../components/global/Loader';
import ToastNotification from '../../components/global/ToastNotification';
import { apiBaseUrl } from '../../constants';
import '../../styles/Login.scss';

// Icons
import { ReactComponent as TwitchIcon } from '../../assets/icons/twitch.svg';
import { ReactComponent as GIcon } from '../../assets/icons/google-g.svg';

// Brand Logos
import noizie from '../../assets/logos/noizie-with-title.svg';

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const { isUserAuth, login, isRedirectingLogin } = useAuthentication();

  const getUserInformation = useCallback(
    async ({ params, provider }) => {
      try {
        login({ params, provider });
        history.push('/');
      } catch (error) {
        console.log(error);
        ToastNotification({
          title: 'Error',
          message: 'A network error ocurred, please try to login again.',
        });
      }
    },
    [history, login]
  );

  useEffect(() => {
    if (location.search && location.search.includes('code')) {
      const provider = location.search.includes('googleapis')
        ? 'google'
        : 'twitch';

      getUserInformation({
        params: location.search,
        provider,
      });
    }
  }, [location.search, getUserInformation]);

  if (isRedirectingLogin) {
    return <Loader isFullscreen />;
  }

  if (isUserAuth) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login-container panel-box-shadow">
      <Row justify="center" align="middle">
        <Col span={14}>
          <Image
            src={noizie}
            className="img-responsive"
            alt="Noizie Music Player"
            preview={false}
          />
        </Col>
        <Col span={20}>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat
            neque molestiae sapiente quae non modi adipisci omnis, libero
            ducimus aspernatur corrupti voluptates officia harum vitae
            dignissimos laudantium enim similique distinctio.
          </p>
        </Col>
        <Col span={24}>
          <Button
            block
            className="twitch-bgcolor"
            href={`${apiBaseUrl}/auth/twitch`}
            icon={<Icon component={TwitchIcon} />}
          >
            Login with Twitch
          </Button>
        </Col>
        <Col span={24}>
          <Button
            block
            className="google-bgcolor"
            href={`${apiBaseUrl}/auth/google`}
            icon={<Icon component={GIcon} />}
          >
            Login with Google
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
