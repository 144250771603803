const CustomRadioButton = ({
  label,
  id,
  type = 'radio',
  value,
  onChange,
  selectedOption,
  required = false,
  name,
}) => (
  <div className="form-row">
    <label htmlFor={id} className="form-row-label-radio">
      <input
        className="form-row-input-radio"
        id={id}
        type={type}
        value={value}
        name={name}
        required={required}
        checked={selectedOption === value}
        onChange={onChange}
      />
      <span className="checkmark" />
      {label}
    </label>
  </div>
);

export default CustomRadioButton;
