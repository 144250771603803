import { Row, Col } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import '../../styles/MainPanel.scss';

// components
import SideBarMenu from '../../components/MainPanel/SideBarMenu';
import MusicPlayer from '../../components/Player/';
import SettingsPanel from '../../components/Settings/';
import Playlists from '../../components/Playlists/';
import SearchPanel from '../../components/Search/';
import AlbumArt from '../../components/Player/AlbumArt/';
import MusicLibrary from '../../components/MusicLibrary/';
import RedirectAs404 from '../../components/global/RedirectAs404';
// context
import { useAuthentication } from '../../context/authContext';

const MainPanel = () => {
  const {
    isUserAuth,
    isLoading,
    userInfo: { subscriptionStatus },
  } = useAuthentication();

  if (isUserAuth && subscriptionStatus !== 'active') {
    return <Redirect to="/payment" />;
  }

  if (!isUserAuth && !isLoading) {
    return <Redirect to="/login" />;
  }

  return (
    <Row className="app-container panel-box-shadow">
      <Col className="siderbar-wrapper" span={4}>
        <SideBarMenu />
      </Col>
      <Col span={20}>
        <Switch>
          <Route exact path="/settings" component={SettingsPanel} />
          <Route path="/playlists" component={Playlists} />
          <Route path="/browse" component={MusicLibrary} />
          <Route exact path="/search" component={SearchPanel} />
          <Route exact path="/" component={AlbumArt} />
          <Route path="*" component={RedirectAs404} />
        </Switch>
        <MusicPlayer />
      </Col>
    </Row>
  );
};

export default MainPanel;
