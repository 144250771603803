import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
import { HashRouter, Route } from 'react-router-dom';
import NotFound from './containers/404';
import { AuthenticationProvider } from './context/authContext';
import './styles/index.scss';

const store = configureStore({
  reducer: rootReducer,
});

if (
  window.location.href.includes('/login') &&
  window.location.href.includes('www.googleapis.com') &&
  window.location.href.includes('%23')
) {
  window.location.href = decodeURIComponent(window.location.href);
}

ReactDOM.render(
  <Provider store={store}>
    <AuthenticationProvider>
      <HashRouter>
        <Route
          render={({ location }) =>
            location.state && location.state.is404 ? <NotFound /> : <App />
          }
        />
      </HashRouter>
    </AuthenticationProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
